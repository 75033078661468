import { Tab } from "@headlessui/react";
import MyTab from "components/basics/MyTab";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import ListScheduledBroadcastMessages from "components/queue/ListScheduledBroadcastMessages";
import ScheduledPostsList from "components/queue/ScheduledPostsList";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const Queue = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  // tab
  const tabs = ["posts", "messages"];
  const tabName = searchParams.get("tab");
  const [selectedIndex, setSelectedIndex] = useState(tabName ? tabs.indexOf(tabName) : 0);
  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedIndex(tab);
      setSearchParams({ tab: tabs[tab] }, { replace: true });
    },
    [setSearchParams, tabs, setSelectedIndex],
  );

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("queue.queue")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasPadding={false}>
        <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
          <Tab.List className="flex justify-around">
            <MyTab title={t("queue.posts") satisfies string} />
            <MyTab title={t("queue.messages") satisfies string} />
          </Tab.List>
          <Tab.Panels className="grow">
            <Tab.Panel className="flex min-h-full flex-col pt-4">
              <ScheduledPostsList className="grow" />
            </Tab.Panel>
            <Tab.Panel className="flex min-h-full flex-col">
              <ListScheduledBroadcastMessages className="grow pt-4" />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </Layout>
  );
};

export default Queue;
