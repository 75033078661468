import classNames from "classnames";
import { NavItemButtonProps, NavItemCommonProps } from "types/navigation.types";

const NavItemButton = ({
  Icon,
  SelectedIcon,
  title,
  onClick,
  isSelected,
  disabled,
}: NavItemCommonProps & Omit<NavItemButtonProps, "type">) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={classNames(
      "flex w-full items-center gap-3 rounded-md border-2 border-transparent p-2 text-gray-700 outline-none duration-100 hover:bg-beige-300 hover:text-gray-500 focus:border-blue-violet active:bg-beige-400 active:text-gray-900 disabled:bg-gray-100 disabled:text-gray-300",
      {
        "bg-beige-400": isSelected,
      },
    )}
  >
    {isSelected && SelectedIcon ? <SelectedIcon className={"h-6 w-6 "} /> : <Icon className={"h-6 w-6 "} />}
    <span className="hidden md:flex">{title}</span>
  </button>
);

export default NavItemButton;
