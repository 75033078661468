import { AddressDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import Callout from "components/atoms/Callout";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Button from "../components/basics/Button";
import TextInput from "../components/basics/TextInput";
import Container from "../components/layouts/Container";
import Layout from "../components/layouts/Layout";
import UserContext from "../contexts/UserContext";
import useUserStore from "../state/userState";
import AddressFields, { addressFieldValidation } from "components/atoms/AddressFields";
import HeaderBar from "components/layouts/HeaderBar";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import SelectCountryInput from "components/molecules/input/SelectCountryInput";

type TData = { firstName: string; lastName: string; country: string } & AddressDto;

const PersonalDataPage = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { updateProfile } = useContext(UserContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const handleOnSubmit = async (data: TData) => {
    const { firstName, lastName, country, ...address } = data;
    if (address.line2 === "") {
      delete address.line2;
    }
    const response = await updateProfile({
      firstName,
      lastName,
      address: {
        ...address,
        country,
      },
    });
    if (!(response instanceof Error)) {
      const profileLink = user.isCreator ? `/creator/${user.username}` : "/profile";
      navigate(profileLink);
    }
  };

  const initialValues = {
    city: user.address.city || "",
    postalCode: user.address.postalCode || "",
    line1: user.address.line1 || "",
    line2: user.address.line2 || "",
    country: user.address.country || "",
    firstName: user.firstName || "",
    lastName: user.lastName || "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t("errorMessage.firstName") || "")
      .max(100, ({ max }) => t("validation.max", { max: max })),
    lastName: Yup.string()
      .required(t("errorMessage.lastName") || "")
      .max(100, ({ max }) => t("validation.max", { max: max })),
    country: Yup.string().required(t("errorMessage.country") || ""),
    ...addressFieldValidation(t),
  });

  return (
    <Layout hideNavigationMobile hideVerificationButton>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("profileMenu.personalData")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
        {() => (
          <Form className={"flex flex-1 flex-col pb-4"}>
            <Container hasPadding={false} className={"px-4 md:px-0"}>
              <div className={"flex flex-1 flex-col md:flex-grow-0"}>
                <Callout>{t("profile.privacyInfo")}</Callout>

                <div className={"my-5"}>
                  <label className={"text-sm text-gray-700"}>{t("profile.email")}</label>
                  <p className={"mb-3 text-gray-500"}>{user.email}</p>

                  <label className={"text-sm text-gray-700"}>{t("profile.birthday")}</label>
                  <p className={"text-gray-500"}>{user.birthday}</p>
                </div>

                <TextInput
                  label={t("profile.firstName")}
                  type="text"
                  name="firstName"
                  placeholder={t("profile.firstName") || ""}
                  required
                />

                <TextInput
                  label={t("profile.lastName")}
                  type="text"
                  name="lastName"
                  placeholder={t("profile.lastName") || ""}
                  required
                />

                <AddressFields />

                <SelectCountryInput name="country" useOther />
              </div>
              <Button
                variant={"primary"}
                text={t("save")}
                type={"submit"}
                className={"mt-4"}
                data-testid={"submit-btn"}
              />
            </Container>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default PersonalDataPage;
