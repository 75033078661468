import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import AuthPopup from "components/popup/AuthPopup";
import PopUp from "components/popup/PopUp";
import { t } from "i18next";
import { ReactNode } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import useNavigationStore from "state/navigationState";
import useUserStore from "../../state/userState";
import FloatingVerificationButton from "../basics/FloatingVerificationButton";
import NavigationBar from "./NavigationBar";
import Sidebar from "./Sidebar";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  hasNavigation?: boolean;
  hideNavigationMobile?: boolean;
  hideVerificationButton?: boolean;
  children?: ReactNode;
  hasStickyBottom?: boolean;
};

const Layout = ({
  hasNavigation = true,
  hideNavigationMobile = false,
  hideVerificationButton = false,
  children,
  hasStickyBottom = false,
}: Props) => {
  const userState = useUserStore<PrivateUserDto>((state) => state.user);
  const showVerificationCTA = userState && !userState.isVerified && userState.isCreator;
  const navigate = useStatefulNavigate();
  const { isCreationPopUpOpen, closeCreationPopUp } = useNavigationStore();

  // TODO: find better solution
  const popUpOptions = [
    {
      title: t("posts.add"),
      onClick: () => {
        navigate("/post/create");
        closeCreationPopUp();
      },
    },
    {
      title: t("addProduct"),
      onClick: () => {
        navigate("/product/create");
        closeCreationPopUp();
      },
    },
  ];

  return (
    <div className="full-height flex flex-col">
      {userState !== null && hasNavigation && <Sidebar />}
      <div className={classNames({ "full-height": hasStickyBottom, "ml-0 grow sm:ml-20 md:ml-60": hasNavigation })}>
        <div
          className={classNames("pr-safe pl-safe pb-safe relative flex h-full flex-1 grow flex-col md:order-2", {
            "full-height md:full-height-without-nav": hasNavigation && hideNavigationMobile,
          })}
        >
          {children ? children : <Outlet />}

          {showVerificationCTA && !hideVerificationButton && <FloatingVerificationButton />}

          {hasNavigation && <NavigationBar hideOnMobile={hideNavigationMobile} />}
        </div>
        <PopUp
          title={t("createContent") || ""}
          options={popUpOptions}
          isOpen={isCreationPopUpOpen}
          onClose={() => closeCreationPopUp()}
        />
      </div>
      <AuthPopup />

      <ScrollRestoration getKey={(location) => location.key} storageKey={window.location.pathname} />
    </div>
  );
};

export default Layout;
