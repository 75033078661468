import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import TwoColumnLayout from "components/layouts/TwoColumnLayout";
import StillUploadingMediaPopup from "components/media/StillUploadingMediaPopup";
import EmptyFolderSelection from "components/molecules/vault/EmptyFolderSelection";
import CreateNewFolderPopup from "components/molecules/vault/popups/CreateNewFolderPopup";
import MediaSelectionStickyBottomBar from "components/organisms/vault/MediaSelectionStickyBottomBar";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import useUploadStore from "state/uploadState";
import useUserStore from "state/userState";
import { TSelectedMedia } from "types/vault/selectedMedia.type";
import FolderDetail from "./FolderDetail";
import Folders from "./Folders";

const VaultParent = () => {
  const navigate = useStatefulNavigate();

  const { state } = useLocation();
  const { uploads } = useUploadStore();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const isSelectingMedia = state?.redirectTo !== undefined;
  const allowMultipleSelect = state?.allowMultipleSelect ?? true;

  const folderId = state?.folderId as string | undefined;

  const [createPopupOpen, setCreatePopupOpen] = useState(false);
  const [stillUploading, setStillUploading] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<TSelectedMedia[]>(state?.selectedMedia ?? []);

  const handleSelectMedia = (media: TSelectedMedia) => {
    if (selectedMedia.findIndex((m) => m._id === media._id) !== -1) {
      if (allowMultipleSelect) setSelectedMedia(selectedMedia.filter((m) => m._id !== media._id));
      else setSelectedMedia([]);
    } else if (allowMultipleSelect) setSelectedMedia([...selectedMedia, { ...media, folderId: folderId }]);
    else setSelectedMedia([{ ...media, folderId: folderId }]);
  };

  const handleSelectAllMedia = (media: TSelectedMedia[]) => {
    if (!isSelectingMedia || allowMultipleSelect) {
      const selectedMediaIds = selectedMedia.map((m) => m._id);
      const newlySelectedMedia = media
        .filter((m) => !selectedMediaIds.includes(m._id))
        .map((m) => ({ ...m, folderId: folderId }));
      setSelectedMedia([...selectedMedia, ...newlySelectedMedia]);
    }
  };

  const handleUnselectAllMedia = (media: TSelectedMedia[]) => {
    const mediaIds = media.map((m) => m._id);
    const filteredSelectedMedia = selectedMedia.filter((m) => !mediaIds.includes(m._id));
    setSelectedMedia([...filteredSelectedMedia]);
  };

  const handleCreateFolder = () => {
    if (user.isVerified) setCreatePopupOpen(true);
    else navigate("/verification/start");
  };

  const handleCloseNewFolderPopup = (folderId?: string) => {
    if (folderId) handleFolderSelection(folderId);
    setCreatePopupOpen(false);
  };

  const handleFolderSelection = (folderId?: string) => {
    // pass the folderId to the state and keep the rest of the state or set it undefined
    navigate(".", { state: { ...state, folderId, selectedMedia: selectedMedia }, replace: true });
    // scroll to top of right column of two column layout
    const rightColumn = document.getElementById("rightColumn");
    if (rightColumn) rightColumn.scrollTop = 0;
  };

  const handleOnSubmit = () => {
    if (uploads.some((m) => m.status === "uploading")) setStillUploading(true);
    else
      navigate(state.redirectTo, {
        state: { data: state.data, selectedMedia: selectedMedia, broadcastId: state.broadcastId },
        replace: true,
      });
  };

  const handleCancel = () => {
    if (uploads.some((m) => m.status === "uploading")) setStillUploading(true);
    else
      navigate(state.redirectTo, {
        state: { data: state.data, selectedMedia: state.selectedMedia ?? [], broadcastId: state.broadcastId },
        replace: true,
      });
  };

  const handleNavigateToMoveMedia = () => {
    // stop if media is still uploading
    if (uploads.some((m) => m.status === "uploading")) setStillUploading(true);
    // navigate to move media page
    else navigate("/vault/move", { state: { selectedMedia, folderId }, replace: true });
  };

  const center = (
    <div className="flex min-h-full grow flex-col">
      {folderId ? (
        <FolderDetail
          folderId={folderId}
          isSelectingMode={isSelectingMedia}
          allowMultipleSelect={allowMultipleSelect}
          onBack={handleFolderSelection}
          onCancel={handleCancel}
          selectedMedia={selectedMedia}
          handleSelectMedia={handleSelectMedia}
          handleSelectAllMedia={handleSelectAllMedia}
          handleUnselectAllMedia={handleUnselectAllMedia}
        />
      ) : (
        <>
          <div className="hidden h-screen xl:block">
            <EmptyFolderSelection onCreateFolder={handleCreateFolder} />
          </div>
          <div className="h-full xl:hidden">
            <Folders
              selectedFolderId={folderId}
              isSelectingMode={isSelectingMedia}
              onFolderSelection={handleFolderSelection}
              onCreateFolder={handleCreateFolder}
            />
          </div>
        </>
      )}
      <div className="grow" />
      <MediaSelectionStickyBottomBar
        currentlySelectedFolderId={folderId}
        isSelectingMedia={isSelectingMedia}
        allowMultipleSelect={allowMultipleSelect}
        selectedMedia={selectedMedia}
        clearMedia={() => setSelectedMedia([])}
        handleOnSubmit={handleOnSubmit}
        handleNavigateToMoveMedia={handleNavigateToMoveMedia}
      />
    </div>
  );

  return (
    <>
      <TwoColumnLayout
        hideNavigationOnMobile
        leftColumn={
          <Folders
            selectedFolderId={folderId}
            isSelectingMode={isSelectingMedia}
            onFolderSelection={handleFolderSelection}
            onCreateFolder={handleCreateFolder}
          />
        }
        rightColumn={center}
      />
      <CreateNewFolderPopup isOpen={createPopupOpen} close={handleCloseNewFolderPopup} />
      <StillUploadingMediaPopup isOpen={stillUploading} setIsOpen={setStillUploading} />
    </>
  );
};

export default VaultParent;
