import {
  ATIcon,
  BEIcon,
  BGIcon,
  CYIcon,
  CZIcon,
  DEIcon,
  DKIcon,
  EEIcon,
  ESIcon,
  FIIcon,
  FRIcon,
  GRIcon,
  HRIcon,
  HUIcon,
  IEIcon,
  ITIcon,
  LTIcon,
  LUIcon,
  LVIcon,
  MTIcon,
  NLIcon,
  OtherIcon,
  PLIcon,
  PTIcon,
  ROIcon,
  SEIcon,
  SIIcon,
  SKIcon,
} from "components/atoms/icons/countries/countries";

type Country = {
  key: string;
  icon: React.ReactElement;
  alwaysAllowed: boolean;
};

export type CountryDisplayItem = Omit<Country, "alwaysAllowed">;

const countryList: Country[] = [
  { key: "at", icon: ATIcon, alwaysAllowed: true },
  { key: "be", icon: BEIcon, alwaysAllowed: false },
  { key: "bg", icon: BGIcon, alwaysAllowed: false },
  { key: "cy", icon: CYIcon, alwaysAllowed: false },
  { key: "cz", icon: CZIcon, alwaysAllowed: false },
  { key: "de", icon: DEIcon, alwaysAllowed: true },
  { key: "dk", icon: DKIcon, alwaysAllowed: false },
  { key: "ee", icon: EEIcon, alwaysAllowed: false },
  { key: "es", icon: ESIcon, alwaysAllowed: false },
  { key: "fi", icon: FIIcon, alwaysAllowed: false },
  { key: "fr", icon: FRIcon, alwaysAllowed: false },
  { key: "gr", icon: GRIcon, alwaysAllowed: false },
  { key: "hr", icon: HRIcon, alwaysAllowed: false },
  { key: "hu", icon: HUIcon, alwaysAllowed: false },
  { key: "ie", icon: IEIcon, alwaysAllowed: false },
  { key: "it", icon: ITIcon, alwaysAllowed: false },
  { key: "lt", icon: LTIcon, alwaysAllowed: false },
  { key: "lu", icon: LUIcon, alwaysAllowed: false },
  { key: "lv", icon: LVIcon, alwaysAllowed: false },
  { key: "mt", icon: MTIcon, alwaysAllowed: false },
  { key: "nl", icon: NLIcon, alwaysAllowed: false },
  { key: "pl", icon: PLIcon, alwaysAllowed: false },
  { key: "pt", icon: PTIcon, alwaysAllowed: false },
  { key: "ro", icon: ROIcon, alwaysAllowed: false },
  { key: "se", icon: SEIcon, alwaysAllowed: false },
  { key: "si", icon: SIIcon, alwaysAllowed: false },
  { key: "sk", icon: SKIcon, alwaysAllowed: false },
];

export const getCountryList = (useOther: boolean): CountryDisplayItem[] => {
  return useOther
    ? countryList
        .filter((country) => country.alwaysAllowed)
        .concat({ key: "etc", icon: OtherIcon, alwaysAllowed: true })
    : countryList;
};
