import { AddressDto, PayoutDataDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import AddressFields, { addressFieldValidation } from "components/atoms/AddressFields";
import Callout from "components/atoms/Callout";
import Checkbox from "components/basics/Checkbox";
import HeaderBar from "components/layouts/HeaderBar";
import SelectCountryInput from "components/molecules/input/SelectCountryInput";
import ValidationError from "components/utilities/ValidationError";
import { Form, Formik, FormikErrors } from "formik";
import removeEmpty from "helper/objectHelper";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import useVerifyUID from "hooks/useVerifyUID";
import SmallBusinessOwner from "pages/SmallBusinessOwner";
import TaxIdInfo from "pages/TaxIdInfo";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineArrowPath,
  HiOutlineCheckCircle,
  HiOutlineInformationCircle,
  HiOutlineXCircle,
} from "react-icons/hi2";
import * as Yup from "yup";
import Button from "../../components/basics/Button";
import TextInput from "../../components/basics/TextInput";
import Container from "../../components/layouts/Container";
import Layout from "../../components/layouts/Layout";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import useUserStore from "../../state/userState";

type TData = {
  firstName: string;
  lastName: string;
  companyName: string;
  bic: string;
  iban: string;
  taxIdNumber: string;
  vatId: string;
  commercialRegisterNumber: string;
  isKleinUnternehmer: boolean;
} & AddressDto;

const PayoutMethod = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { maloumClient } = useContext(MaloumClientContext);
  const payoutData = useUserStore<PayoutDataDto>((state) => state.user?.payoutData);
  const { isVatValid, isVatValidLoading, validateVat, isVatValidError } = useVerifyUID();

  const [showSmallBusinessPage, setShowSmallBusinessPage] = useState(false);
  const [showTaxIdInfoPage, setShowTaxIdInfoPage] = useState(false);
  const [allowInvoice, setAllowInvoice] = useState(payoutData?.hasAcceptedCreditInvoice || false);
  const [isInitialVatFieldState, setIsInitialVatFieldState] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [showVatLoading, setShowVatLoading] = useState(false);
  const [showVatError, setShowVatError] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const onSubmit = useCallback(
    async (data: TData) => {
      const {
        firstName,
        lastName,
        companyName,
        bic,
        iban,
        taxIdNumber,
        vatId,
        commercialRegisterNumber,
        isKleinUnternehmer,
        ...address
      } = data;

      if (allowInvoice) {
        if (address.line2 === "") {
          delete address.line2;
        }
        await maloumClient.users
          .setPayoutData({
            ...removeEmpty({
              firstName,
              lastName,
              companyName,
              bic,
              iban,
              taxIdNumber,
              vatId,
              commercialRegisterNumber,
              isKleinUnternehmer,
            }),
            hasAcceptedCreditInvoice: allowInvoice,
            address: address,
          })
          .then((response: PrivateUserDto) => {
            if (!(response instanceof Error)) {
              useUserStore.setState({ user: response });
              navigate("/payout");
            }
          });
      } else {
        setHasError(true);
      }
    },
    [allowInvoice, setHasError],
  );

  useEffect(() => {
    setHasError(false);
  }, [allowInvoice]);

  const uidIcon = useMemo(() => {
    if (isInitialVatFieldState) {
      return null;
    } else if (showVatLoading || isVatValidLoading) {
      return <HiOutlineArrowPath className={"h-6 w-6 animate-spin"} />;
    } else if (showVatError || isVatValidError || isVatValid === false) {
      return <HiOutlineXCircle className="h-6 w-6" />;
    } else if (isVatValid) {
      return <HiOutlineCheckCircle className={"h-6 w-6"} />;
    } else {
      return null;
    }
  }, [isVatValid, isVatValidLoading, isInitialVatFieldState, showVatError, showVatLoading]);

  const handleVatChange = useCallback((vatId?: string) => {
    if (vatId?.length === 0) {
      setShowVatError(false);
      setShowVatLoading(false);
      setIsInitialVatFieldState(true);
    } else if (vatId && vatId?.length < 7) {
      clearTimeout(timer);

      setShowVatLoading(true);
      setShowVatError(false);
      setIsInitialVatFieldState(false);

      const newTimer = setTimeout(() => {
        if (vatId !== undefined) {
          setShowVatLoading(false);
          setShowVatError(true);
        }
      }, 500);

      setTimer(newTimer);
    } else if (vatId && vatId?.length > 7 && vatId?.length < 30) {
      clearTimeout(timer);

      setShowVatLoading(true);
      setShowVatError(false);
      setIsInitialVatFieldState(false);

      const newTimer = setTimeout(() => {
        if (vatId !== undefined) {
          validateVat(vatId);
          setShowVatLoading(false);
          setShowVatError(false);
        }
      }, 500);

      setTimer(newTimer);
    } else if (vatId && vatId?.length > 30) {
      clearTimeout(timer);

      setShowVatLoading(true);
      setShowVatError(false);
      setIsInitialVatFieldState(false);

      const newTimer = setTimeout(() => {
        setShowVatLoading(false);
        setShowVatError(true);
      }, 500);

      setTimer(newTimer);
    }
  }, []);

  const initialValues = {
    city: payoutData?.bankAccount.address.city || "",
    postalCode: payoutData?.bankAccount.address.postalCode || "",
    line1: payoutData?.bankAccount.address.line1 || "",
    line2: payoutData?.bankAccount.address.line2 || "",
    country: payoutData?.bankAccount.address.country || "",
    firstName: payoutData?.bankAccount?.firstName || "",
    lastName: payoutData?.bankAccount?.lastName || "",
    companyName: payoutData?.bankAccount?.companyName || "",
    bic: payoutData?.bankAccount?.bic || "",
    iban: payoutData?.bankAccount?.iban || "",
    taxIdNumber: payoutData?.taxIdNumber || "",
    vatId: payoutData?.vatId || "",
    commercialRegisterNumber: payoutData?.commercialRegisterNumber || "",
    isKleinUnternehmer: payoutData?.isKleinUnternehmer || false,
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(100, ({ max }) => t("validation.max", { max: max })),
    lastName: Yup.string().max(100, ({ max }) => t("validation.max", { max: max })),
    companyName: Yup.string().max(100, ({ max }) => t("validation.max", { max: max })),
    taxIdNumber: Yup.string().required(t("errorMessage.taxIdNumber") || ""),
    bic: Yup.string().max(30, ({ max }) => t("validation.max", { max: max })),
    iban: Yup.string()
      .required(t("errorMessage.iban") || "")
      .max(30, ({ max }) => t("validation.max", { max: max })),
    country: Yup.string().required(t("errorMessage.country") || ""),
    ...addressFieldValidation(t),
  });

  const validateValues = (values: TData) => {
    const errors: FormikErrors<TData> = {};
    if (!values.companyName && (!values.firstName || !values.lastName)) {
      errors.firstName = t("errorMessage.nameOrCompany") || undefined;
      errors.lastName = t("errorMessage.nameOrCompany") || undefined;
      errors.companyName = t("errorMessage.nameOrCompany") || undefined;
      return errors;
    }

    if (!values.firstName && values.lastName) {
      errors.firstName = t("errorMessage.firstName") || undefined;
      return errors;
    }

    if (!values.lastName && values.firstName) {
      errors.lastName = t("errorMessage.lastName") || undefined;
      return errors;
    }

    return errors;
  };

  return (
    <>
      {showSmallBusinessPage && <SmallBusinessOwner onBack={() => setShowSmallBusinessPage(false)} />}
      {showTaxIdInfoPage && <TaxIdInfo onBack={() => setShowTaxIdInfoPage(false)} />}
      <div className={classNames({ hidden: showSmallBusinessPage || showTaxIdInfoPage })}>
        <Layout hideNavigationMobile hasStickyBottom>
          <HeaderBar>
            <HeaderBar.SubPage>
              <HeaderBar.Left>
                <HeaderBar.Cancel />
              </HeaderBar.Left>
              <HeaderBar.Center>
                <HeaderBar.Title>{t("payout.method")}</HeaderBar.Title>
              </HeaderBar.Center>
              <HeaderBar.Right />
            </HeaderBar.SubPage>
          </HeaderBar>
          <Formik
            initialValues={initialValues}
            validateOnMount
            validate={validateValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, setFieldValue, values }) => {
              return (
                <>
                  <Container className="pb-4">
                    <h1 className={"my-2 text-2xl font-semibold"}>{t("payout.method")}</h1>

                    <Callout className="mb-2">{t("profile.privacyInfo")}</Callout>

                    <Form className={"flex flex-1 flex-col pb-4"}>
                      <div className={"flex flex-1 flex-col md:flex-grow-0"}>
                        <div className={"mb-3"}>
                          <SelectCountryInput name="country" />
                        </div>
                        <TextInput
                          label={t("profile.firstName")}
                          type="text"
                          name="firstName"
                          placeholder={t("profile.firstName") || ""}
                          required
                          autoComplete="off"
                          spacing="mb-3"
                        />

                        <TextInput
                          label={t("profile.lastName")}
                          type="text"
                          name="lastName"
                          placeholder={t("profile.lastName") || ""}
                          required
                          autoComplete="off"
                          spacing="mb-3"
                        />

                        <TextInput
                          label={t("profile.companyName")}
                          hint={t("profile.companyNameHint") || ""}
                          type="text"
                          name="companyName"
                          placeholder={t("profile.companyName") || ""}
                          required
                          autoComplete="off"
                          spacing="mb-3"
                        />

                        <AddressFields />

                        <TextInput
                          label={t("payment.iban")}
                          type="text"
                          name="iban"
                          placeholder={t("payment.iban") || ""}
                          required
                          autoComplete="off"
                          spacing="mb-3"
                        />

                        <TextInput
                          label={t("payment.bic")}
                          type="text"
                          name="bic"
                          placeholder={t("payment.bic") || ""}
                          autoComplete="off"
                          spacing="mb-3"
                        />

                        <TextInput
                          label={t("payment.taxIdNumber")}
                          type="text"
                          name="taxIdNumber"
                          required
                          placeholder={t("payment.taxIdNumber") || ""}
                          autoComplete="off"
                          suffix={
                            <button type="button" onClick={() => setShowTaxIdInfoPage(true)}>
                              <HiOutlineInformationCircle className={"ml-1 h-5 w-5 text-gray-700"} />
                            </button>
                          }
                          spacing="mb-3"
                        />

                        <TextInput
                          label={t("payout.vat")}
                          type="text"
                          name="vatId"
                          placeholder={t("payout.vat") || ""}
                          onChange={() => handleVatChange(values.vatId)}
                          suffix={uidIcon}
                          autoComplete="off"
                        />
                        {isVatValid === false && !isInitialVatFieldState && (
                          <ValidationError className="mt-2" message={t("errorMessage.vat")} />
                        )}

                        <TextInput
                          label={t("payout.commercialRegisterNumber")}
                          type="text"
                          name="commercialRegisterNumber"
                          placeholder={t("payout.commercialRegisterNumber") || ""}
                          autoComplete="off"
                        />

                        <div className={"mt-3"}>
                          <Checkbox
                            title={
                              <div className={"flex"}>
                                {t("smallBusiness.check")}
                                <button
                                  className={"-m-2 p-2"}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowSmallBusinessPage(true);
                                  }}
                                >
                                  <HiOutlineInformationCircle className={"ml-1 h-5 w-5 text-gray-700"} />
                                </button>
                              </div>
                            }
                            checked={values.isKleinUnternehmer}
                            name="isKleinUnternehmer"
                            onChange={(value) => setFieldValue("isKleinUnternehmer", value)}
                          />
                        </div>
                      </div>
                    </Form>

                    <div className="mt-8">
                      <Checkbox
                        title={<span>{t("payout.terms")}</span>}
                        checked={allowInvoice}
                        onChange={setAllowInvoice}
                      />
                      {hasError && (
                        <ValidationError className="p-0" message={t("errorMessage.terms")} hasBackground={false} />
                      )}
                      <Button
                        variant={"primary"}
                        text={t("save")}
                        type={"submit"}
                        onClick={handleSubmit}
                        data-testid={"submit-payout-btn"}
                        className="mt-4"
                      />
                    </div>
                  </Container>
                </>
              );
            }}
          </Formik>
        </Layout>
      </div>
    </>
  );
};

export default PayoutMethod;
