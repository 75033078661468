import { useTranslation } from "react-i18next";
import { NavItemButtonProps, NavItemCommonProps, NavItemLinkProps } from "types/navigation.types";
import NavItemButton from "../buttons/NavItemButton";
import NavItemLink from "../links/NavItemLink";

type NavItemProps = NavItemCommonProps & (NavItemLinkProps | NavItemButtonProps);

const NavItem = ({ Icon, SelectedIcon, title, type, to, onClick, isSelected, disabled }: NavItemProps) => {
  const { t } = useTranslation();

  switch (type) {
    case "button":
      return (
        <NavItemButton
          type={type}
          Icon={Icon}
          SelectedIcon={SelectedIcon}
          title={t(`navigation.${title}`)}
          onClick={onClick}
          isSelected={isSelected}
          disabled={disabled}
        />
      );
    case "link":
      return (
        <NavItemLink
          type={type}
          Icon={Icon}
          SelectedIcon={SelectedIcon}
          title={t(`navigation.${title}`)}
          to={to}
          disabled={disabled}
        />
      );
    default:
      throw Error;
  }
};

export default NavItem;
