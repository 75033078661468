import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  value: string;
  className?: string;
  "data-testid"?: string;
};

const RadioButtonCard = ({ children, value, className, "data-testid": dataTestId }: Props) => {
  return (
    <RadioGroup.Option value={value} data-testid={dataTestId}>
      {({ checked }) => (
        <div
          className={classNames(
            "flex cursor-pointer items-center rounded-md border p-3.5 text-sm focus:outline-none xxs:text-base",
            { "border-red-900": checked, "border-gray-100": !checked },
            className,
          )}
        >
          <div className="flex-1">
            <RadioGroup.Label as="span" className={"flex grow items-center"}>
              <span className="flex flex-1 items-center justify-between">{children}</span>
              <span
                className={classNames(
                  "m-1 flex h-3 w-3 items-center justify-center rounded-full ring-1 ring-offset-2",
                  {
                    "bg-red-900 ring-red-900": checked,
                    "bg-white ring-gray-400": !checked,
                  },
                )}
              />
            </RadioGroup.Label>
          </div>
        </div>
      )}
    </RadioGroup.Option>
  );
};

export default RadioButtonCard;
