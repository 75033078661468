import { useCallback, useContext } from "react";
import {
  ChatProductDto,
  PaymentMethodDto,
  ProductDto,
  PublicPriceDto,
  PublicUserDto,
} from "@neolime-gmbh/api-gateway-client";
import { useQuery } from "react-query";
import MaloumClientContext from "../../contexts/MaloumClientContext";

const useCheckout = (
  id: string | null,
  type: "SUBSCRIPTION" | "PRODUCT" | "TIP" | "CHAT_PRODUCT" | "CHAT_UNLOCK" | null,
  amount: number | null,
  selectedCountry?: string,
  selectedPaymentMethod?: PaymentMethodDto.paymentMethod,
) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchItem = useCallback(async (): Promise<PublicUserDto | ChatProductDto | ProductDto | undefined> => {
    if (!id) throw Error;

    if (type === "TIP") {
      return undefined;
    }

    if (type === "CHAT_UNLOCK") {
      return maloumClient.users.getProfileByUsername(id) as Promise<PublicUserDto>;
    }

    if (type === "PRODUCT") {
      return maloumClient.products.getById(id);
    }

    if (type === "SUBSCRIPTION") {
      return maloumClient.users.getProfileByUsername(id) as Promise<PublicUserDto>;
    }

    if (type === "CHAT_PRODUCT") {
      return maloumClient.chatProducts.getById(id);
    }

    throw Error;
  }, [type, id]);

  const {
    data: item,
    isLoading: itemIsLoading,
    isError: itemIsError,
  } = useQuery(["checkout-item", { type, id }], fetchItem);

  const calculatePrice = useCallback(
    async (creatorId: string, netAmount: number): Promise<PublicPriceDto> => {
      return maloumClient.pricing.calculateBuyingPrice({
        creator: creatorId,
        netAmount: netAmount,
        country: selectedCountry,
        paymentMethod: selectedPaymentMethod,
      });
    },
    [selectedCountry, selectedPaymentMethod],
  );

  const fetchPrice = useCallback(async (): Promise<PublicPriceDto> => {
    if (!id) throw Error;

    //TODO: check if item is undefined

    if (type === "TIP" && amount) {
      return calculatePrice(id, amount);
    }

    if (type === "CHAT_UNLOCK" && amount) {
      const user = item as PublicUserDto;
      return calculatePrice(user._id, amount);
    }

    if (type === "PRODUCT") {
      const product = item as ProductDto;
      return calculatePrice(product.createdBy._id, product.price.net);
    }

    if (type === "SUBSCRIPTION") {
      const creator = item as PublicUserDto;
      return await calculatePrice(creator?._id, creator?.subscriptionPrice?.net);
    }

    if (type === "CHAT_PRODUCT") {
      const chatProduct = item as ChatProductDto;
      return await calculatePrice(chatProduct.createdBy._id, chatProduct.price.net);
    }

    throw Error;
  }, [id, amount, item, selectedCountry, selectedPaymentMethod]);

  const {
    data: price,
    isLoading: priceIsLoading,
    isError: priceIsError,
  } = useQuery(["checkout-price", { id, amount, item, selectedCountry, selectedPaymentMethod }], fetchPrice);

  return { item, price, isLoading: itemIsLoading || priceIsLoading, isError: itemIsError || priceIsError };
};

export default useCheckout;
