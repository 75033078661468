import { format } from "date-fns";
import { de, enUS } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePickerColumn from "./DateTimePickerColumn";

interface DateTimePickerProps {
  value?: Date;
  onChange?: (date: Date) => void;
  minuteInterval?: number;
  daysInTheFuture?: number;
}

type Hours = {
  hour: number;
  minutes: number[];
};

type Days = {
  daysAfterToday: number;
  hours: Hours[];
};

export const DateTimePicker = ({
  value = new Date(),
  onChange,
  minuteInterval = 15,
  daysInTheFuture = 45,
}: DateTimePickerProps) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const options = {
    locale: currentLanguage.includes("de") ? de : enUS,
  };
  // constants
  const now = new Date();

  const schedulableDays = useMemo(
    () => generateAvailableDates(minuteInterval, daysInTheFuture),
    [minuteInterval, daysInTheFuture, new Date().getMinutes()],
  );

  const isValueInFuture = value.valueOf() > Date.now();

  const initialDayInFuture = calculateInitialDayInFuture(value);

  const initialHour = isValueInFuture ? value.getHours() : schedulableDays[0].hours[0].hour;
  const initialMinute = isValueInFuture ? value.getMinutes() : schedulableDays[0].hours[0].minutes[0];

  const [hour, setHour] = useState<number>(initialHour);
  const [minute, setMinute] = useState<number>(initialMinute);
  const [day, setDay] = useState<number>(initialDayInFuture);

  useEffect(() => {
    const newDate = new Date();
    newDate.setHours(hour, minute, 0, 0);
    newDate.setDate(now.getDate() + day);

    if (isNaN(newDate.getTime())) {
      return;
    }

    onChange?.(newDate);
  }, [hour, minute, day]);

  const handleDayChange = (daysInFuture: number) => {
    setDay(daysInFuture);

    const smallestHour = schedulableDays[0].hours[0].hour;
    const smallestMinute = schedulableDays[0].hours[0].minutes[0];

    if (daysInFuture === 0 && hour < smallestHour) {
      setHour(smallestHour);
      if (minute < smallestMinute) setMinute(smallestMinute);
    }
  };

  const handleHourChange = (newHour: number) => {
    setHour(newHour);

    const smallestHour = schedulableDays[0].hours[0].hour;
    const smallestMinute = schedulableDays[0].hours[0].minutes[0];

    if (day === 0 && newHour <= smallestHour && minute <= smallestMinute) {
      setHour(smallestHour);
      setMinute(smallestMinute);
    }
  };

  const indexOfCurrentHour = schedulableDays[day]?.hours.findIndex((h) => h?.hour === hour);
  const daysOnlyArray = schedulableDays.map((day) => day.daysAfterToday);

  return (
    <div className="relative inline-block w-full font-sans">
      <div className="relative flex h-[15rem] w-full items-center justify-between overflow-hidden">
        <div className="absolute left-0 top-1/2 -z-[1] h-[3rem] w-full -translate-y-1/2 rounded-xl bg-gray-100" />

        <DatePickerColumn
          values={daysOnlyArray}
          selectedValue={day}
          formatValues={(date) => dayInFutureToString(date, options)}
          onValueChange={(day) => handleDayChange(day)}
          className="w-64"
        />
        <DatePickerColumn
          values={schedulableDays[day]?.hours.map((h) => h.hour) || []}
          selectedValue={hour}
          formatValues={(hour) => hour.toString().padStart(2, "0")}
          onValueChange={(hour) => handleHourChange(hour)}
          className="w-24"
        />
        <p className="rellative mt-1.5 h-9 text-center text-xl">:</p>
        <DatePickerColumn
          values={schedulableDays[day]?.hours[indexOfCurrentHour]?.minutes || []}
          selectedValue={minute}
          formatValues={(minute) => minute?.toString().padStart(2, "0")}
          onValueChange={(minute) => setMinute(minute)}
          className="mr-6 w-24"
        />

        <div className="pointer-events-none absolute left-0 top-0 h-[7.5rem] w-full bg-gradient-to-b from-white to-white/0" />
        <div className="pointer-events-none absolute bottom-0 left-0 h-[7.5rem] w-full bg-gradient-to-t from-white to-white/0" />
      </div>
    </div>
  );
};

function remainingMinutesInHour(startMinute: number, minuteInterval: number): number[] {
  if (60 - startMinute <= minuteInterval) return [] as number[];
  const remainingMinutes = [];
  let currentMinute = 0;
  while (currentMinute < 60) {
    if (currentMinute >= startMinute) remainingMinutes.push(currentMinute);
    currentMinute += minuteInterval;
  }
  return remainingMinutes;
}

function remainingHoursInDay(startHour: number, startMinute: number, minuteInterval: number): Hours[] {
  if (24 * 60 - (startHour * 60 + startMinute) <= minuteInterval) return [] as Hours[];
  const remainingHours: Hours[] = [];

  const remainingMinutesInStartHour = remainingMinutesInHour(startMinute, minuteInterval);
  if (remainingMinutesInStartHour.length) {
    remainingHours.push({
      hour: startHour,
      minutes: remainingMinutesInStartHour,
    });
  }

  let currentHour = startHour + 1;
  while (currentHour < 24) {
    remainingHours.push({
      hour: currentHour,
      minutes: remainingMinutesInHour(0, minuteInterval),
    });
    currentHour++;
  }

  return remainingHours;
}

function generateAvailableDates(minuteInterval: number, daysInTheFuture: number) {
  const now = new Date();
  const schedulableDays: Days[] = [];

  const currentDay = remainingHoursInDay(now.getHours(), now.getMinutes() + 1, minuteInterval);

  if (currentDay.length > 0) {
    schedulableDays.push({
      daysAfterToday: 0,
      hours: currentDay,
    });
  }
  for (let daysAfterToday = 1; daysAfterToday < daysInTheFuture; daysAfterToday++) {
    schedulableDays.push({
      daysAfterToday,
      hours: remainingHoursInDay(0, 0, minuteInterval),
    });
  }
  return schedulableDays;
}

function dayInFutureToString(day: number, options: {}) {
  const totalTime = new Date().valueOf() + day * 24 * 60 * 60 * 1000;
  return format(totalTime, "EE MMM dd", options);
}

function calculateInitialDayInFuture(targetDate: Date) {
  if (isNaN(targetDate.getTime())) {
    return 0;
  }
  const today = new Date();
  const targetDateCopy = new Date(targetDate?.toISOString());
  const diffInDays = Math.ceil(
    (targetDateCopy.setHours(0, 0, 0, 0) - today.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24),
  );
  return Math.max(diffInDays, 0);
}
