import {
  ChatAccessSettingsDto,
  MediaCandidateDto,
  PrivateUserDto,
  PublicUserDto,
} from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Card from "components/atoms/Card";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import AutoGrowTextarea from "components/basics/AutoGrowTextarea";
import Button from "components/basics/Button";
import Media from "components/media/Media";
import MediaRemoveButton from "components/media/MediaRemoveButton";
import StillUploadingMediaPopup from "components/media/StillUploadingMediaPopup";
import UploadingMedia from "components/media/UploadingMedia";
import ChatPriceSettingPopup from "components/molecules/popup/ChatPriceSettingPopup";
import SubscribePopup from "components/profile/SubscribePopup";
import useChatItem from "hooks/useChatItem.hook";
import useCurrency from "hooks/useCurrency.hook";
import useMessages from "hooks/useMessages.hook";
import useSendMessage from "hooks/useSendMessage.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import useUpload from "hooks/useUpload.hook";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineBanknotes,
  HiOutlinePaperAirplane,
  HiOutlinePencil,
  HiOutlinePhoto,
  HiPlus,
  HiPlusSmall,
  HiXMark,
} from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import useUploadStore from "state/uploadState";
import useUserStore from "state/userState";
import { TSelectedMedia } from "types/vault/selectedMedia.type";
import TipPopupBottom from "./TipPopupBottom";
import UnlockChatCard from "./UnlockChatCard";
import BlockedCallout from "./chatSettings/block/BlockedCallout";

type Props = { chatId: string };

const MessageBar = ({ chatId }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  const user = useUserStore<PrivateUserDto>((state) => state.user);

  // chat item
  const { chat, moveChatToPrimary } = useChatItem(chatId);

  //messages for pop-up
  const { messages, isLoading: isMessagesLoading } = useMessages(chatId);

  // send messages
  const { sendTextMessage, sendMediaMessage, sendChatProduct } = useSendMessage(chatId);

  // popups
  const [pricePopupIsOpen, setPricePopupIsOpen] = useState(false);
  const [openTipPopUp, setOpenTipPopUp] = useState(false);
  const [isTipHintPopUpOpen, setIsTipHintPopUpOpen] = useState(false);
  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);

  // media (creator)
  const { state, pathname } = useLocation();
  const [selectedMedia, setSelectedMedia] = useState((state?.selectedMedia ?? []) as TSelectedMedia[]);

  // media (fan)
  const hiddenInputField = useRef<HTMLInputElement>(null);
  const { handleUpload, imageMimeTypes, videoMimeTypes, getMediaDimensions, isValidAspectRatio } = useUpload();
  const { uploads, removeUpload, resetUploads } = useUploadStore();
  const acceptedMediaTypes = imageMimeTypes.concat(videoMimeTypes).join(", ");
  const [stillUploading, setStillUploading] = useState(false);

  // text message state
  const [message, setMessage] = useState(state?.data?.message ?? "");

  // price
  const [digitalProductPrice, setDigitalProductPrice] = useState<number | undefined>(state?.data?.digitalProductPrice);
  const { displayCurrency } = useCurrency();

  //convert chat to PRIMARY if REQUEST
  const mockPrimary = state?.data?.mockPrimary;

  const chatLocked =
    !chat?.unlocked &&
    chat?.chatPartner.chatAccessSettings?.exclusiveChatType !== ChatAccessSettingsDto.exclusiveChatType.ALL &&
    chat?.chatPartner.isCreator;

  const isMediaSelected = (user.isCreator && selectedMedia.length > 0) || (!user.isCreator && uploads.length > 0);

  const handleClickMediaSelectionStart = async () => {
    // stop user from uploading media if unverified creator and redirect to verification page
    if (user.isCreator && !user.isVerified) navigate("/verification/start");
    // open subscribe popup if chat is locked
    else if (chatLocked) setIsSubscribePopupOpen(true);
    else if (user.isCreator) {
      // start media selection
      const data = { message, digitalProductPrice, mockPrimary };
      navigate("/vault", {
        state: {
          redirectTo: pathname,
          data,
          allowMultipleSelect: true,
          selectedMedia,
          fanId: chat?.chatPartner._id,
        },
        replace: true,
      });
      // else if user is fan
    } else {
      hiddenInputField.current?.click();
    }
  };

  const handleMediaSelection = (e: ChangeEvent<HTMLInputElement>) => {
    // if files then upload
    if (e.target.files) uploadMedia(e.target.files);
  };

  const uploadMedia = async (files: FileList) => {
    if (mockPrimary) {
      moveChatToPrimary();
    }
    // store selected media
    const mediasTmp = Array.from(files);
    // this starts uploads in succession as starting them all at once (eg .map) axios cancels the requests (url generation)
    for (const media of mediasTmp) {
      const dimensions = await getMediaDimensions(media);
      if (isValidAspectRatio(dimensions.width, dimensions.height)) await handleUpload({ media, dimensions });
      else {
        // TODO show upload error because of invalid aspect ratio
      }
    }
  };

  const handleRemoveMedia = (mediaId: string) => {
    if (user.isCreator) setSelectedMedia(selectedMedia.filter((media) => media._id !== mediaId));
    else removeUpload(mediaId);
  };

  // send message
  const handleSubmit = () => {
    if (mockPrimary) {
      moveChatToPrimary();
    }
    if (!user.isCreator && uploads.length > 0 && uploads.some((upload) => upload.status === "uploading")) {
      setStillUploading(true);
      return;
    }
    const mediaIds = user.isCreator ? selectedMedia.map((u) => u._id) : uploads.map((u) => u.id);
    // if chat not loaded yet return
    if (!chat) return;
    if (chatLocked) setIsSubscribePopupOpen(true);
    else if (mediaIds.length > 0) {
      // send chat product if a price was set
      if (digitalProductPrice) {
        sendChatProduct(mediaIds, digitalProductPrice, chat.chatPartner._id, message || undefined);
      }
      // send media message
      else {
        sendMediaMessage(mediaIds, message || undefined);
      }
    } // send text message
    else if (message) {
      sendTextMessage(message);
    }

    // reset
    setMessage("");
    setSelectedMedia([]);
    resetUploads();
    setDigitalProductPrice(undefined);
    window.history.replaceState({}, "");
  };

  // open tip popup and trigger GTM event
  const handleOpenTipPopup = () => {
    // Google Tag Manager: Tip Popup Opened Event
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "tip_popup_opened",
      creator_id: chat?.chatPartner._id,
    });
    if (chatLocked) setIsSubscribePopupOpen(true);
    else setOpenTipPopUp(true);
    // hide hint when tip popup is opened -> not shown again after this
    setIsTipHintPopUpOpen(false);
  };

  // if tip hint callout should be shown
  useEffect(() => {
    // set to false as default
    setIsTipHintPopUpOpen(false);

    // return if chat is not loaded yet
    if (!chat) return;

    // return if isTrusted flag is true of one user in chat
    if (user.isTrusted || chat.chatPartner.isTrusted) return;

    if (isMessagesLoading || messages.length > 0) return;

    // dont show hint if chat is not unlocked
    if (chatLocked) return;

    // show hint if other user is a verified creator and current user is fan
    if (chat.chatPartner.isCreator && chat.chatPartner.isVerified && !user.isCreator) setIsTipHintPopUpOpen(true);
    // show hint if other user is a fan and current user is verified creator
    else if (!chat.chatPartner.isCreator && user.isCreator && user.isVerified) setIsTipHintPopUpOpen(true);
  }, [chat, user, messages]);

  // reset chat state on mount
  useEffect(() => {
    // reset upload state if user is no creator
    if (!user.isCreator) resetUploads();
  }, []);

  if (!chat) return <></>;

  const hasProcessingMedia = user.isCreator
    ? selectedMedia.some((media) => media.uploadStatus === MediaCandidateDto.uploadStatus.PROCESSING)
    : false;

  const disabled = chat.chatPartner.isBlocked || chat.chatPartner.hasBlocked;

  return (
    <>
      <div className="sticky bottom-0 z-[51] mx-auto w-full max-w-xl">
        <BlockedCallout chat={chat} />
        {chatLocked && !disabled && <UnlockChatCard chat={chat} className="z-10" />}
        {isTipHintPopUpOpen && (
          <div className="z-10" data-testid="tip-hint-popup">
            <Card>
              <div className="px-1 py-2">
                <div className="mb-2 flex justify-between">
                  <h3 className="text-xl font-semibold text-gray-900">{t("tipHint.header")}</h3>
                  <button onClick={() => setIsTipHintPopUpOpen(false)}>
                    <HiXMark className="h-6 w-6 text-gray-500" />
                  </button>
                </div>
                <p>{!user.isCreator ? t("tipHint.descriptionForFan") : t("tipHint.descriptionForCreator")}</p>
                {!user.isCreator && (
                  <Button
                    text={t("tipHint.cta")}
                    onClick={handleOpenTipPopup}
                    className="mt-4"
                    data-testid="tip-hint-button"
                  />
                )}
              </div>
            </Card>
          </div>
        )}
        <div className="bg-white pb-4 pt-4">
          {isMediaSelected && (
            <div className="rounded-t-md border-l border-r border-t border-gray-100">
              <div className="relative mb-2 flex w-full justify-between px-2 pt-2">
                <div className="text-xs text-gray-700">
                  {user.isCreator
                    ? `${selectedMedia.length}/${selectedMedia.length}`
                    : `${uploads.length}/${uploads.length}`}
                </div>
                {user.isCreator && (
                  <TernaryButton
                    size="xs"
                    className="group absolute right-1 top-[-2px] flex items-center gap-1"
                    onClick={() => setPricePopupIsOpen(true)}
                  >
                    {digitalProductPrice === undefined ? (
                      <>
                        {t("chatProduct.addPrice")}
                        <HiPlusSmall className="h-4 w-4 text-gray-700 group-hover:text-gray-500 group-active:text-gray-800" />
                      </>
                    ) : (
                      <>
                        {t("chatProduct.priceToView")}: {displayCurrency(digitalProductPrice)}
                        <HiOutlinePencil className="h-4 w-4 text-gray-700 group-hover:text-gray-500 group-active:text-gray-800" />
                      </>
                    )}
                  </TernaryButton>
                )}
              </div>
              {hasProcessingMedia && (
                <div className="mx-2 mb-2 rounded-md bg-beige-400 p-2 text-sm">{t("processingStates.chat")}</div>
              )}
              <div className="flex w-fit max-w-full gap-2 overflow-x-auto bg-white px-2 pb-2">
                {selectedMedia.map((media) => (
                  <div className="relative h-[8.5rem] w-[8.5rem] shrink-0" key={media._id}>
                    <Media media={media} thumbnail={media.thumbnail} className="rounded-md" />
                    <MediaRemoveButton
                      selectedMediaId={media._id}
                      onRemoveMedia={handleRemoveMedia}
                      data-testid="remove-media"
                    />
                  </div>
                ))}
                {!user.isCreator &&
                  uploads.map((upload) => (
                    <div className="relative h-[8.5rem] w-[8.5rem] shrink-0" key={upload.id}>
                      <UploadingMedia upload={upload} className="rounded-md" />
                      <MediaRemoveButton
                        selectedMediaId={upload.id}
                        onRemoveMedia={handleRemoveMedia}
                        data-testid="remove-media"
                      />
                    </div>
                  ))}
                <button
                  className="flex h-[8.5rem] min-w-[8.5rem] items-center justify-center rounded-md border border-dashed border-red-900"
                  onClick={handleClickMediaSelectionStart}
                >
                  <HiPlus className="h-10 w-10 fill-red-900" />
                </button>
              </div>
            </div>
          )}
          <div className="flex gap-2">
            <button
              className={classNames("px-0.5 py-2 disabled:text-gray-300", {
                hidden: isMediaSelected,
              })}
              onClick={handleClickMediaSelectionStart}
              disabled={disabled}
            >
              <HiOutlinePhoto className="h-6 w-6" />
            </button>
            {chat.chatPartner.isCreator && chat.chatPartner.isVerified && (
              <button
                className={classNames("px-0.5 py-2 disabled:text-gray-300", {
                  hidden: isMediaSelected,
                })}
                onClick={handleOpenTipPopup}
                disabled={disabled}
              >
                <HiOutlineBanknotes className="h-6 w-6" />
              </button>
            )}
            <div
              className={classNames("flex grow flex-wrap items-end rounded-b-md bg-gray-100 pr-1", {
                "rounded-t-md": selectedMedia.length === 0 || (!user.isCreator && uploads.length === 0),
              })}
            >
              <AutoGrowTextarea
                placeholder={t("message.placeholder")}
                maxRows={4}
                value={message}
                onChange={setMessage}
                maxLength={2000}
                className="flex w-0 grow border-none bg-transparent px-3 py-2 text-base outline-none !ring-0 placeholder:text-gray-400 focus:outline-none disabled:text-gray-300"
                onSubmit={handleSubmit}
                disabled={disabled}
              />
              <button onClick={handleSubmit} className="p-2.5 disabled:text-gray-300" disabled={disabled}>
                <HiOutlinePaperAirplane className="h-5 w-5 text-lg" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <TipPopupBottom creatorId={chat.chatPartner._id} open={openTipPopUp} setOpen={setOpenTipPopUp} />

      <ChatPriceSettingPopup
        isOpen={pricePopupIsOpen}
        setIsOpen={setPricePopupIsOpen}
        onDigitalProductPriceChange={setDigitalProductPrice}
        digitalProductNetPrice={digitalProductPrice}
      />
      {chat.chatPartner.isCreator && (
        <SubscribePopup
          creator={chat.chatPartner as PublicUserDto}
          open={isSubscribePopupOpen}
          setOpen={setIsSubscribePopupOpen}
        />
      )}

      <StillUploadingMediaPopup isOpen={stillUploading} setIsOpen={setStillUploading} />
      <input
        type="file"
        multiple
        accept={acceptedMediaTypes}
        onInput={handleMediaSelection}
        className="hidden"
        ref={hiddenInputField}
      />
    </>
  );
};

export default MessageBar;
