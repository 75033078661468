import { ChatProductDto, CheckoutChatProductDto, CheckoutResponseDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { formatDurationInSecondsIntoHoursMinutesSeconds } from "helper/dateAndTimeHelper";
import { useContext } from "react";
import { useMutation } from "react-query";

function useChatProduct() {
  const { maloumClient } = useContext(MaloumClientContext);

  const getChatProductById = async (chatProductId: string): Promise<ChatProductDto> => {
    return maloumClient.chatProducts.getById(chatProductId);
  };

  const postCheckoutChatProduct = async (
    checkoutChatProductObject: CheckoutChatProductDto,
  ): Promise<CheckoutResponseDto> => {
    return maloumClient.checkout.checkoutChatProduct(checkoutChatProductObject);
  };

  const getContentAmount = (contentType: string, videoLength?: number) => {
    switch (contentType) {
      case "picture":
        return "1";
      case "video":
        return formatDurationInSecondsIntoHoursMinutesSeconds(videoLength);
      default:
        return "";
    }
  };

  // TODO: WTF? use useQuery here not useMutation... maybe to only load when needed?
  const {
    data: chatProduct,
    isLoading: isChatProductLoading,
    isError: isChatProductError,
    mutate: mutateChatProduct,
  } = useMutation({
    mutationFn: (chatProductId: string) => {
      return getChatProductById(chatProductId);
    },
  });

  const { mutate: checkoutChatProduct } = useMutation({
    mutationFn: (checkoutChatProductObject: CheckoutChatProductDto) => {
      return postCheckoutChatProduct(checkoutChatProductObject);
    },
  });

  return {
    getContentAmount,
    chatProduct,
    isChatProductLoading,
    isChatProductError,
    mutateChatProduct,
    checkoutChatProduct,
    getChatProductById,
  };
}

export default useChatProduct;
