import { RadioGroup } from "@headlessui/react";
import RadioButtonCard from "components/molecules/RadioButtonCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/basics/Button";
import Container from "../components/layouts/Container";
import Layout from "../components/layouts/Layout";
import HeaderBar from "components/layouts/HeaderBar";
import { CountryDisplayItem, getCountryList } from "const/countries/countryList";

type Props = {
  selected?: string | null;
  onSubmit: (value: CountryDisplayItem) => void;
  onBack: () => void;
  useOther: boolean;
};

const SelectCountryPage = ({ selected, onSubmit, onBack, useOther }: Props) => {
  const { t } = useTranslation();

  const countries = getCountryList(useOther);

  const countryTranslations = countries
    .map((c) => ({
      key: c.key,
      translation: t(`countries.${c.key.toUpperCase()}`),
    }))
    .sort((a, b) => {
      const priorityOrder = { etc: 3, de: -2, at: -1 };
      const aPriority = priorityOrder[a.key as keyof typeof priorityOrder] || 0;
      const bPriority = priorityOrder[b.key as keyof typeof priorityOrder] || 0;
      if (aPriority || bPriority) return aPriority - bPriority;
      return a.translation.localeCompare(b.translation);
    });

  const [selectedItem, setSelectedItem] = useState<CountryDisplayItem>(
    countries.find((c) => c.key.toLowerCase() === (selected ? selected.toLocaleLowerCase() : "de"))!,
  );

  const handleSelectItem = (countryKey: string) => {
    setSelectedItem(countries.find((c) => c.key === countryKey)!);
  };

  const handleSubmit = () => {
    if (selectedItem) onSubmit(selectedItem);
  };

  return (
    <Layout hideNavigationMobile hideVerificationButton>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel onClick={onBack} />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("selectCountry")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container>
        <div className="mb-4 mt-4 text-2xl font-semibold">{t("selectYourCountry")}</div>
        <div className="flex grow flex-col md:flex-grow-0">
          <RadioGroup value={selectedItem?.key} onChange={handleSelectItem} className="grid grid-cols-1 gap-2">
            {countryTranslations.map((country) => {
              const countryItem = countries.find((c) => c.key === country.key)!;
              return (
                <RadioButtonCard value={countryItem.key} key={countryItem.key}>
                  <div className="flex items-center gap-2">
                    {countryItem.icon}
                    <div>{country.translation}</div>
                  </div>
                </RadioButtonCard>
              );
            })}
          </RadioGroup>
        </div>

        <div className="sticky bottom-0 flex w-full bg-white pb-4 pt-2">
          <Button
            variant="primary"
            disabled={!selectedItem}
            onClick={handleSubmit}
            data-testid="select-country-submit"
            text={t("save")}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default SelectCountryPage;
