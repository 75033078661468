const formatIBAN = (iban: string) => {
  // Remove any non-alphanumeric characters from the input string
  iban = iban.replace(/[^a-zA-Z0-9]/g, "");

  // Split the IBAN into groups of four characters
  const chunks = iban.match(/.{1,4}/g);

  // Join the groups with a space separator
  if (chunks) {
    return chunks.join(" ");
  }

  // If no groups were found, return the original input string
  return iban;
};

const capitalizeFirstLetter = (string: string): string => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export { capitalizeFirstLetter, formatIBAN };
