import { RadioGroup } from "@headlessui/react";
import { PrivateUserDto, PublicPriceDto } from "@neolime-gmbh/api-gateway-client";
import Button from "components/basics/Button";
import RadioOption from "components/basics/RadioOption";
import SelectItemUnderline from "components/utilities/SelectItemUnderline";
import ValidationError from "components/utilities/ValidationError";
import useCurrency from "hooks/useCurrency.hook";
import i18n from "i18n";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useUserStore from "state/userState";
import Checkbox from "components/basics/Checkbox";
import { PaymentOption } from "types";

type ItemType = "SUBSCRIPTION" | "PRODUCT" | "TIP" | "CHAT_PRODUCT" | "CHAT_UNLOCK";

type Props = {
  price?: PublicPriceDto;
  paymentOptions: PaymentOption[];
  selectedPaymentOption: PaymentOption;
  className?: string;
  "data-testid"?: string;
  type: ItemType;
  selectedCountry?: string;
  setSelectedPaymentOption: (paymentOption: PaymentOption) => void;
  showCountrySelect: () => void;
  handleBuy: () => Promise<void>;
};

const comparePaymentMethods = (pm1: PaymentOption, pm2: PaymentOption) => {
  if (pm1.referenceId || pm2.referenceId) return pm1.referenceId === pm2.referenceId;
  return pm1.paymentMethod === pm2.paymentMethod;
};

const Payment = ({
  price,
  paymentOptions,
  selectedPaymentOption,
  className,
  "data-testid": dataTestId,
  type,
  selectedCountry,
  setSelectedPaymentOption,
  showCountrySelect,
  handleBuy,
}: Props) => {
  const { t } = useTranslation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { displayCurrency } = useCurrency();

  const [showCountryNotSelectedError, setShowCountryNotSelectedError] = useState(false);
  const [showTermsCheckboxError, setShowTermsCheckboxError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickedTermsCheckbox, setClickedTermsCheckbox] = useState(false);

  const showTermsCheckbox = type === "SUBSCRIPTION" && !user.acceptedTermsAndConditions;

  const handleShowCountrySelect = () => {
    showCountrySelect();
    setShowCountryNotSelectedError(false);
  };

  const handleOnClick = async () => {
    if (!selectedCountry) {
      setShowCountryNotSelectedError(true);
      return;
    }

    if (showTermsCheckbox && !clickedTermsCheckbox) {
      setShowTermsCheckboxError(true);
      return;
    }

    setLoading(true);

    handleBuy().catch(() => {
      setLoading(false);
    });
  };

  return (
    <div className={className ?? ""} data-testid={dataTestId}>
      <div className="text-2xl font-semibold">{t("checkout.payment")}</div>
      {/* Only show country select for product and tips if country was not selected yet */}
      {!user.address.country && type !== "PRODUCT" && (
        <div className="mt-3">
          <label className={"mb-2 text-sm text-gray-700"}>{`${t("address.country")} *`}</label>
          <SelectItemUnderline
            value={selectedCountry ? t(`countries.${selectedCountry}`) : t("selectCountry")}
            onClick={handleShowCountrySelect}
            data-testid={"checkout-country-select"}
          />
          {showCountryNotSelectedError && (
            <ValidationError message={t("selectCountryError")} hasBackground={false} className={"mt-2"} />
          )}
        </div>
      )}
      {/* TODO Radio Option is not correctly selected after reload */}
      <RadioGroup
        value={selectedPaymentOption}
        onChange={setSelectedPaymentOption}
        by={comparePaymentMethods}
        className="mt-4"
      >
        <div className="relative -space-y-px bg-white">
          {paymentOptions.map((payment) => (
            <RadioOption
              key={payment.referenceId ?? payment.paymentMethod}
              value={payment}
              title={payment.description}
              rightSlot={payment.children}
            />
          ))}
        </div>
      </RadioGroup>
      <div className="h-[7rem]" />

      <div className="fixed bottom-0 left-0 mx-auto grid w-full grid-cols-1 items-center bg-white sm:left-auto sm:w-[calc(100%-5rem)] sm:max-w-xl sm:-translate-x-4 md:w-[calc(100%-15rem)]">
        {showTermsCheckbox && (
          <div className="p-3">
            <span className="text-center text-sm text-gray-500">
              <Checkbox
                title={
                  <Trans i18nKey="checkout.termsAndConditions">
                    <a
                      className="underline"
                      href={
                        i18n.language.startsWith("de")
                          ? "https://www.maloum.com/de/terms"
                          : "https://www.maloum.com/en/terms"
                      }
                      target="_blank"
                      rel="noreferrer"
                    />
                  </Trans>
                }
                onChange={setClickedTermsCheckbox}
                checked={clickedTermsCheckbox}
              />
            </span>
            {showTermsCheckboxError && (
              <ValidationError message={t("checkout.termsCheckboxError")} hasBackground={false} className={"mt-2"} />
            )}
          </div>
        )}
        <div className="grid grid-cols-2 items-center border-t border-t-gray-100 p-3">
          {price && <span>{displayCurrency(price.gross)}</span>}
          {!price && <span className="h-5 w-16 rounded-md bg-gray-100" />}
          <Button
            onClick={handleOnClick}
            data-testid="proceed-checkout-button"
            text={!loading ? (t("buyNow") as string) : undefined}
            IconFront={
              loading ? () => <AiOutlineLoading3Quarters className="my-0.5 h-5 w-5 animate-spin" /> : undefined
            }
            disabled={loading || !price}
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;
