import { Dialog } from "@headlessui/react";
import SelectItemUnderline from "components/utilities/SelectItemUnderline";
import ValidationError from "components/utilities/ValidationError";
import { CountryDisplayItem } from "const/countries/countryList";
import { useField } from "formik";
import SelectCountryPage from "pages/SelectCountry";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  useOther?: boolean;
};

//TODO: to implement more widely
const SelectCountryInput = ({ name, useOther = false }: Props) => {
  const { t } = useTranslation();
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const [showCountrySelect, setShowCountrySelect] = useState(false);

  const selectCountry = (country: CountryDisplayItem) => {
    setValue(country.key.toUpperCase());
    setShowCountrySelect(false);
  };

  return (
    <>
      <label className={"mb-2 text-sm text-gray-700"}>{t("address.country")} *</label>
      <SelectItemUnderline
        value={value ? t(`countries.${value}`) : t("selectCountry")}
        onClick={() => setShowCountrySelect(true)}
      />
      {meta.error && meta.touched && <ValidationError message={meta.error} hasBackground={false} className={"mt-2"} />}

      <Dialog unmount open={showCountrySelect} onClose={() => setShowCountrySelect(false)} className="relative z-50">
        <div className="fixed inset-0 flex items-center justify-center overflow-y-auto">
          <Dialog.Panel className="w-full max-h-full overflow-scroll bg-white">
            <SelectCountryPage
              selected={value}
              onBack={() => setShowCountrySelect(false)}
              onSubmit={selectCountry}
              useOther={useOther}
            />
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default SelectCountryInput;
