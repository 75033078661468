import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

interface PickerColumnProps {
  values: number[];
  selectedValue: number;
  formatValues?: (value: number) => string;
  onValueChange: (value: number) => void;
  className?: string;
}

const ITEM_HEIGHT = 44;
const DEBOUNCE_DELAY = 150;

const DatePickerColumn = ({
  values,
  selectedValue,
  formatValues = (value) => value.toString(),
  onValueChange,
  className,
}: PickerColumnProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const debounceTimeout = useRef<number | undefined>(undefined);

  const [previousValuesLength, setPreviousValuesLength] = useState(values.length);

  const scrollToPosition = useCallback((position: number, behavior: ScrollBehavior = "smooth") => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: position, behavior });
    }
  }, []);

  const snapToNearestItem = useCallback(
    (behavior: ScrollBehavior = "smooth") => {
      if (!containerRef.current) return;

      const scrollTop = containerRef.current.scrollTop;
      const index = Math.round(scrollTop / ITEM_HEIGHT);
      const snappedValue = values[index];

      if (snappedValue !== selectedValue) {
        onValueChange(snappedValue);
      }
      scrollToPosition(index * ITEM_HEIGHT, behavior);
    },
    [values, selectedValue, onValueChange, scrollToPosition],
  );

  const handleScroll = useCallback(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = window.setTimeout(() => {
      snapToNearestItem();
    }, DEBOUNCE_DELAY);
  }, [snapToNearestItem]);

  const handleItemClick = useCallback(
    (value: number) => {
      const index = values.indexOf(value);
      if (index > -1) {
        onValueChange(value);
        scrollToPosition(index * ITEM_HEIGHT);
      }
    },
    [onValueChange, scrollToPosition, values],
  );

  useEffect(() => {
    const selectedIndex = values.indexOf(selectedValue);
    if (selectedIndex > -1) {
      scrollToPosition(selectedIndex * ITEM_HEIGHT, "smooth");
    }
  }, [selectedValue]);

  useEffect(() => {
    if (!containerRef.current) return;
    const scrollTop = containerRef.current.scrollTop;
    const selectedIndex = values.indexOf(selectedValue);
    const calculatedTop = selectedIndex * ITEM_HEIGHT;
    if (previousValuesLength !== values.length || scrollTop != calculatedTop) {
      setPreviousValuesLength(values.length);
      if (selectedIndex > -1) {
        scrollToPosition(selectedIndex * ITEM_HEIGHT, "instant");
      }
    }
  }, [values]);

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames("scrollbar-hide relative h-[15rem] overflow-y-auto scroll-smooth pt-[2.45rem]", className)}
      onScroll={handleScroll}
      role="listbox"
      aria-activedescendant={`picker-item-${selectedValue}`}
    >
      <div className="flex flex-col items-center gap-2 pb-28 pt-16">
        {values.map((value) => (
          <div
            key={value}
            id={`picker-item-${value}`}
            role="option"
            aria-selected={value === selectedValue}
            tabIndex={0}
            className={classNames(
              "h-9 cursor-pointer text-center text-xl leading-9",
              value === selectedValue ? "text-gray-900" : "text-gray-900/50",
            )}
            onClick={() => handleItemClick(value)}
            onKeyDown={(e) => e.key === "Enter" && handleItemClick(value)}
          >
            {formatValues(value)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatePickerColumn;
