import {
  BE,
  BG,
  DK,
  DE,
  EE,
  FI,
  FR,
  GR,
  IE,
  IT,
  HR,
  LV,
  LT,
  LU,
  MT,
  NL,
  AT,
  PL,
  PT,
  RO,
  SE,
  SK,
  SI,
  ES,
  CZ,
  HU,
  CY,
} from "country-flag-icons/react/1x1";
import OtherCountry from "./OtherCountry";

export const BEIcon = <BE width={16} height={12} title="Belgium"  className="shadow-country-icon"/>;
export const BGIcon = <BG width={16} height={12} title="Bulgaria" className="shadow-country-icon"/>;
export const DKIcon = <DK width={16} height={12} title="Denmark" className="shadow-country-icon"/>;
export const DEIcon = <DE width={16} height={12} title="Germany" className="shadow-country-icon"/>;
export const EEIcon = <EE width={16} height={12} title="Estonia" className="shadow-country-icon"/>;
export const FIIcon = <FI width={16} height={12} title="Finland" className="shadow-country-icon"/>;
export const FRIcon = <FR width={16} height={12} title="France" className="shadow-country-icon"/>;
export const GRIcon = <GR width={16} height={12} title="Greece" className="shadow-country-icon"/>;
export const IEIcon = <IE width={16} height={12} title="Ireland" className="shadow-country-icon"/>;
export const ITIcon = <IT width={16} height={12} title="Italy" className="shadow-country-icon"/>;
export const HRIcon = <HR width={16} height={12} title="Croatia" className="shadow-country-icon"/>;
export const LVIcon = <LV width={16} height={12} title="Latvia" className="shadow-country-icon"/>;
export const LTIcon = <LT width={16} height={12} title="Lithuania" className="shadow-country-icon"/>;
export const LUIcon = <LU width={16} height={12} title="Luxembourg" className="shadow-country-icon"/>;
export const MTIcon = <MT width={16} height={12} title="Malta" className="shadow-country-icon"/>;
export const NLIcon = <NL width={16} height={12} title="Netherlands" className="shadow-country-icon"/>;
export const ATIcon = <AT width={16} height={12} title="Austria" className="shadow-country-icon"/>;
export const PLIcon = <PL width={16} height={12} title="Poland" className="shadow-country-icon"/>;
export const PTIcon = <PT width={16} height={12} title="Portugal" className="shadow-country-icon"/>;
export const ROIcon = <RO width={16} height={12} title="Romania" className="shadow-country-icon"/>;
export const SEIcon = <SE width={16} height={12} title="Sweden" className="shadow-country-icon"/>;
export const SKIcon = <SK width={16} height={12} title="Slovakia" className="shadow-country-icon"/>;
export const SIIcon = <SI width={16} height={12} title="Slovenia" className="shadow-country-icon"/>;
export const ESIcon = <ES width={16} height={12} title="Spain" className="shadow-country-icon"/>;
export const CZIcon = <CZ width={16} height={12} title="Czech Republic" className="shadow-country-icon"/>;
export const HUIcon = <HU width={16} height={12} title="Hungary" className="shadow-country-icon"/>;
export const CYIcon = <CY width={16} height={12} title="Cyprus" className="shadow-country-icon"/>;

export const OtherIcon = <OtherCountry width={16} height={16} />;
