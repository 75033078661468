import BroadcastCard from "components/organisms/broadcast/BroadcastCard";
import {
  ChatListDto,
  ChatProductMessageContentDto,
  MediaMessageContentDto,
  MessageContentDto,
  PictureCandidateDto,
  ScheduledBroadcastDto,
  ThumbnailCandidateDto,
  VideoCandidateDto,
} from "@neolime-gmbh/api-gateway-client";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import PopUp, { TOption } from "components/popup/PopUp";
import Button from "components/basics/Button";
import useScheduledBroadcastMessages from "hooks/useScheduledBroadcastMessages.hook";
import { HiOutlineUsers } from "react-icons/hi2";
import PillButton from "components/atoms/buttons/PillButton";
import useSelectedListsOverviewNavigation from "hooks/navigation/useSelectedListsOverviewNavigation";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useLocation } from "react-router-dom";
import useChatProduct from "hooks/useChatProduct.hook";
import { TSelectedMedia } from "types/vault/selectedMedia.type";

type Props = {
  broadcast: ScheduledBroadcastDto;
  className?: string;
};

const ScheduledBroadcastCard = ({ broadcast, className }: Props) => {
  const { removeBroadcastMessage } = useScheduledBroadcastMessages(false);
  const [showPopup, setShowPopup] = useState(false);

  const { getChatProductById } = useChatProduct();

  const { pathname } = useLocation();
  const navigate = useStatefulNavigate();

  const labels = (
    <ReceiverLabel
      recipientCount={broadcast.recipientCount}
      includeLists={broadcast.includeFromLists}
      excludeLists={broadcast.excludeFromLists}
    />
  );

  const onEdit = async () => {
    let digitalProductPrice: number | undefined;
    let selectedMedia: TSelectedMedia[] | undefined;

    if (broadcast.content.type === MessageContentDto.type.CHAT_PRODUCT) {
      const chatProductContent = broadcast.content as ChatProductMessageContentDto;
      const chatProduct = await getChatProductById(chatProductContent._id);
      digitalProductPrice = chatProduct.price.net;
      selectedMedia = mapMediaContentToSelectedMedia(chatProduct.media, chatProduct.thumbnails);
    }

    if (broadcast.content.type === MessageContentDto.type.MEDIA) {
      const mediaContent = broadcast.content as MediaMessageContentDto;
      selectedMedia = mapMediaContentToSelectedMedia(mediaContent.media, mediaContent.thumbnails);
    }
    const data = {
      // @ts-expect-error wrong typing
      message: broadcast.content.text,
      digitalProductPrice: digitalProductPrice,
      scheduledAt: broadcast.scheduledAt,
    };
    navigate("/chat/new/message", {
      state: { redirectTo: `${pathname}?tab=messages`, data, selectedMedia, broadcastId: broadcast._id },
      replace: true,
    });
  };

  return (
    <>
      <BroadcastCard
        date={new Date(broadcast.scheduledAt)}
        // @ts-expect-error wrong typing
        text={broadcast.content.text}
        content={broadcast.content}
        onOpenPopup={() => setShowPopup(true)}
        labels={labels}
        className={className}
      />

      {showPopup && (
        <OptionsPopup
          onClose={() => setShowPopup(false)}
          onDelete={() => removeBroadcastMessage(broadcast._id)}
          onEdit={onEdit}
        />
      )}
    </>
  );
};

export default ScheduledBroadcastCard;

type OptionsPopupProps = {
  onClose: () => void;
  onDelete: () => void;
  onEdit: () => void;
};

const OptionsPopup = ({ onClose, onDelete, onEdit }: OptionsPopupProps) => {
  const { t } = useTranslation();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const popupOptions = [
    { title: t("delete"), onClick: () => setShowConfirmationPopup(true) },
    // { title: t("edit"), onClick: () => handleEditClick() }, TODO fix editing
  ] satisfies TOption[];

  const handleDeleteClick = () => {
    setShowConfirmationPopup(false);
    onClose();
    onDelete();
  };

  const handleEditClick = async () => {
    onClose();
    await onEdit();
  };

  return showConfirmationPopup ? (
    <PopUp isOpen onClose={() => setShowConfirmationPopup(false)} title={t("delete") ?? ""}>
      <div className="py-4 text-center">{t("queue.sureDeleteMessage")}</div>
      <div className="flex gap-2">
        <Button variant="secondary" text={t("cancel")} onClick={() => setShowConfirmationPopup(false)} />
        <Button variant="primary" text={t("delete")} onClick={handleDeleteClick} />
      </div>
    </PopUp>
  ) : (
    <PopUp isOpen onClose={onClose} title={t("queue.modifyMessage") ?? ""} options={popupOptions} />
  );
};

type ReceiverLabelProps = {
  recipientCount: number;
  includeLists: ChatListDto[];
  excludeLists: ChatListDto[];
};

const ReceiverLabel = ({ recipientCount, includeLists, excludeLists }: ReceiverLabelProps) => {
  const { t } = useTranslation();
  const navigateToSelectedListsOverview = useSelectedListsOverviewNavigation();

  const label = t("queue.memberCount", { count: recipientCount });

  const handleGoToSelection = () => {
    navigateToSelectedListsOverview({ includeLists, excludeLists });
  };

  return (
    <PillButton onClick={handleGoToSelection} className="!w-fit">
      <HiOutlineUsers className="h-4 w-4" /> {label}
    </PillButton>
  );
};

const mapMediaContentToSelectedMedia = (
  media: (PictureCandidateDto | VideoCandidateDto)[],
  thumbnails: ThumbnailCandidateDto[],
) => {
  return media.map((mediaItem, idx) => {
    const thumbnail = thumbnails?.[idx] ?? undefined;
    const mediaId = getMediaIdFromThumbnailUrl(thumbnail?.url);

    return {
      _id: mediaId ?? "",
      width: mediaItem.width,
      height: mediaItem.height,
      thumbnail: thumbnail,
      uploadStatus: mediaItem.uploadStatus,
      type: mediaItem.type,
      url: mediaItem.url,
    } satisfies TSelectedMedia;
  });
};

const getMediaIdFromThumbnailUrl = (thumbnailUrl?: string) => {
  if (!thumbnailUrl) return "";
  return (
    thumbnailUrl
      .split("/")
      .find((part) => part.includes(".jpg"))
      ?.split(".jpg")[0] ?? ""
  );
};
