import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import PopUpPanel from "./PopUpPanel";

export type TOption = {
  title: string;
  onClick: () => void;
  testId?: string;
};

type Props = {
  title?: string;
  options?: TOption[];
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  footer?: ReactNode;
  preventClose?: boolean;
  "data-testid"?: string;
};

const PopUp = ({
  title,
  options,
  isOpen = false,
  onClose,
  children,
  footer,
  preventClose = false,
  "data-testid": dataTestId,
}: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={() => !preventClose && onClose} data-testid={dataTestId}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-300/70 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 h-full overflow-y-auto">
          <div className="flex min-h-full w-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={"w-full"}>
                <PopUpPanel
                  title={title}
                  options={options}
                  onClose={onClose}
                  footer={footer}
                  preventClose={preventClose}
                >
                  {children}
                </PopUpPanel>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PopUp;
