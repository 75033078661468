import i18n from "i18next";
import { capitalizeFirstLetter } from "./stringFormatHelper";

const oneDayInMs = 1000 * 60 * 60 * 24;
const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;

const isOver18 = (date: Date) => {
  const ageDifMs = Date.now() - date.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  return age >= 18;
};

const padWithZeroes = (input: number, length: number) => {
  return input.toString().padStart(length, "0");
};

/**
 * Used for Comments and Chat
 */
const generateTimeTag = (date: Date, showTime = false) => {
  const t = i18n.t;
  const today = new Date().setHours(0, 0, 0, 0);
  const yesterday = new Date(today - oneDayInMs);

  let timeTag;
  if (date.valueOf() - today > 0) {
    timeTag = "";
  } else if (date.valueOf() - yesterday.valueOf() > 0) {
    timeTag = t("yesterday");
  } else {
    timeTag = formatDateWithStoredLanguage(date, false);
  }
  if (showTime || !timeTag) {
    timeTag += ` ${padWithZeroes(date.getHours(), 2)}:${padWithZeroes(date.getMinutes(), 2)}`;
  }
  return timeTag;
};

/**
 * Used for Subscribers and Followers
 */
const generateTimeTagForFans = (date: Date, showTime = false) => {
  const t = i18n.t;
  const today = new Date().setHours(0, 0, 0, 0);
  const yesterday = new Date(today - oneDayInMs);

  let timeTag;
  // in the future (excluding today)
  if (date.valueOf() - today > 86400000) {
    timeTag = formatDateWithStoredLanguage(date, false);
  } else if (date.valueOf() - today > 0) {
    timeTag = t("today");
  } else if (date.valueOf() - yesterday.valueOf() > 0) {
    timeTag = t("yesterday");
  } else {
    timeTag = formatDateWithStoredLanguage(date, false);
  }
  if (showTime || !timeTag) {
    timeTag += ` ${padWithZeroes(date.getHours(), 2)}:${padWithZeroes(date.getMinutes(), 2)}`;
  }
  return timeTag;
};

/**
 * Used for Post and Products
 */
const getDateRelativeToNow = (date: Date) => {
  const t = i18n.t;

  const now = new Date();
  const difference = now.getTime() - date.getTime();
  const differenceInMinutes = difference / (1000 * 60);
  const differenceInHours = difference / (1000 * 60 * 60);
  const differenceInDays = difference / oneDayInMs;

  if (differenceInMinutes < 60) {
    return t("minutesAgo", { count: Math.floor(differenceInMinutes) });
  } else if (differenceInHours < 24) {
    return t("hoursAgo", { count: Math.floor(differenceInHours) });
  } else if (differenceInDays < 30) {
    return t("daysAgo", { count: Math.floor(differenceInDays) });
  } else {
    return formatDateWithStoredLanguage(date, false);
  }
};

const formatDateWithStoredLanguage = (date: Date, yearNumeric = true) => {
  const storedLang = localStorage.getItem("i18nextLng"); // retrieve the i18n language settings and apply them to the data formatting
  const options: Intl.DateTimeFormatOptions = {
    year: yearNumeric ? "numeric" : "2-digit",
    month: "2-digit",
    day: "2-digit",
  };

  return date.toLocaleDateString(storedLang ?? "de-DE", options);
};

const formatDateWithStoredLanguageAndTime = (date: Date) => {
  const storedLang = localStorage.getItem("i18nextLng"); // retrieve the i18n language settings and apply them to the data formatting
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  return date.toLocaleDateString(storedLang ?? "de-DE", options);
};

const formatDateWithStoredLanguageAndDay = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  const storedLang = localStorage.getItem("i18nextLng"); // retrieve the i18n language settings and apply them to the data formatting
  return date.toLocaleDateString(storedLang || "de-DE", options);
};

const formatDurationInSecondsIntoHoursMinutesSeconds = (time?: number) => {
  if (!time || isNaN(time)) {
    return "00:00";
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  if (hours) {
    return `${hours}:${minutes.toString().padStart(2, "0")} `;
  } else return `${minutes}:${seconds}`;
};

const getAndFormatFirstDayOfNextMonthDate = () => {
  const currentLanguage = i18n.language;
  // Get current date
  const currentDate = new Date();

  // Calculate the first day of the next month
  const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);

  // Format the date string as "1. April 2023"
  return (
    nextMonth.getDate() +
    ". " +
    new Intl.DateTimeFormat(currentLanguage, { month: "long", year: "numeric" }).format(nextMonth)
  );
};

const getAndFormatCurrentMonth = () => {
  const currentLanguage = i18n.language;
  // Get current date
  const currentDate = new Date();

  // Get month and year from current date
  const currentMonth = new Intl.DateTimeFormat(currentLanguage, { month: "long" }).format(currentDate);
  const currentYear = currentDate.getFullYear();

  // Format the date string as "April 2023"
  return currentMonth + " " + currentYear;
};

const formatSchedulingDate = (date: Date, useOn = false) => {
  const currentLanguage = i18n.language;
  const t = i18n.t;
  const currentDate = new Date();
  const currentTime = new Date().getTime();
  const givenTime = date.getTime();

  if (currentTime - givenTime <= FIVE_MINUTES_IN_MILLISECONDS && currentTime > givenTime) {
    return `${capitalizeFirstLetter(t("today"))}, ${capitalizeFirstLetter(t("now"))}`;
  }

  if (date.getDate() === currentDate.getDate() && date.getHours() >= currentDate.getHours()) {
    const options: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };

    const formattedTime = new Intl.DateTimeFormat(currentLanguage, options).format(date);
    return `${capitalizeFirstLetter(t("today"))}, ${formattedTime}`;
  }

  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = new Intl.DateTimeFormat(currentLanguage, options).format(date);
  return `${useOn ? t("on") : ""} ${formattedDate}`;
};

export {
  formatDateWithStoredLanguage,
  formatDateWithStoredLanguageAndDay,
  formatDateWithStoredLanguageAndTime,
  formatDurationInSecondsIntoHoursMinutesSeconds,
  formatSchedulingDate,
  generateTimeTag,
  generateTimeTagForFans,
  getAndFormatCurrentMonth,
  getAndFormatFirstDayOfNextMonthDate,
  getDateRelativeToNow,
  isOver18,
  padWithZeroes,
};
